/* eslint-disable camelcase */
import React from 'react';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

import stringAvatar from '../utils/stringAvatar';

function UserAvatar({ user, style }) {
  const { first_name, last_name } = user;
  return <Avatar sx={style}>{stringAvatar(first_name, last_name)}</Avatar>;
}

UserAvatar.propTypes = {
  user: PropTypes.object,
  style: PropTypes.object
};

export default UserAvatar;
