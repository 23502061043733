import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ color, height }) => {
  if (color === 'white') {
    return <img src="/static/logo_white.png" alt="logo" height={height} />;
  }

  return <img src="/static/logo_black.png" alt="logo" height={height} />;
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired
};

export default Logo;
