import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import useAuth from './hooks/useAuth';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication')));

// Dashboard pages
const Home = Loadable(lazy(() => import('./pages/dashboard/Home')));
const Reports = Loadable(lazy(() => import('./pages/dashboard/Reports')));
const Review = Loadable(lazy(() => import('./pages/dashboard/Review')));
const Sourcers = Loadable(lazy(() => import('./pages/dashboard/Sourcers')));
const Settings = Loadable(lazy(() => import('./pages/dashboard/Settings')));

// Error pages
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

export default function Router() {
  const { user } = useAuth();
  const homePage = user && user.isContentReviewer ? <Review /> : <Home />;

  const element = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/home',
          element: <Navigate to="/" replace />
        },
        {
          path: '/',
          element: homePage
        },
        {
          path: 'reports',
          element: <Reports />
        },
        {
          path: 'sourcers',
          element: <Sourcers />
        },
        {
          path: 'settings',
          element: <Settings />
        }
      ]
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: '404',
          element: <NotFound />
        },
        {
          path: '500',
          element: <ServerError />
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ]);
  return element;
}
