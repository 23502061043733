import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import './styles/fonts.css';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import store from './store';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()]
  });
}

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <AuthProvider>
                <Sentry.ErrorBoundary fallback="An error has occurred">
                  <App />
                  <Toaster />
                </Sentry.ErrorBoundary>
              </AuthProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
