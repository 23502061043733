import axiosInstance from '../utils/axios';

class AccountApi {
  updateUserInfo(data, userId) {
    return axiosInstance.patch(`users/${userId}/`, data);
  }

  changePassword(payload) {
    const { currentPassword, newPassword } = payload;
    return axiosInstance.post('users/me/set_password/', {
      current_password: currentPassword,
      new_password: newPassword
    });
  }

  me = () => {
    return axiosInstance
      .get('users/me/')
      .then((response) => {
        const user = response.data.data;
        if (user) {
          return user;
        }
        return response.data.message;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const accountApi = new AccountApi();
