import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import UserAvatar from '../UserAvatar';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import useAuth from '../../hooks/useAuth';
import { ROLES } from '../../constants';

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const { user } = useAuth();
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const version = process.env.REACT_APP_COMMIT_REF.toString().substring(0, 7);
  const isCreatorRole = user.isCreatorSourcer || user.isCreatorManager;

  const sections = [
    {
      items: [
        {
          title: 'Home',
          path: '/',
          icon: <HomeOutlinedIcon fontSize="small" />
        },
        {
          title: 'Settings',
          path: '/settings',
          icon: <SettingsOutlinedIcon fontSize="small" />
        }
      ]
    }
  ];

  if (user.isSourcerManager) {
    const index = sections[0].items.length - 1;
    sections[0].items.splice(index, 0, {
      title: 'Sourcers',
      path: '/sourcers',
      icon: <GroupOutlinedIcon fontSize="small" />
    });
  }

  if (user.isCreatorManager) {
    const index = sections[0].items.length - 1;
    sections[0].items.splice(index, 0, {
      title: 'Manage Team',
      path: '/sourcers',
      icon: <GroupOutlinedIcon fontSize="small" />
    });
  }

  if (!isCreatorRole && !user.isContentReviewer) {
    const index = sections[0].items.length - 1;
    sections[0].items.splice(index, 0, {
      title: 'Reports',
      path: '/reports',
      icon: <ArticleOutlinedIcon fontSize="small" />
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderNavSections = () => {
    return sections.map((section) => (
      <NavSection
        key={section}
        pathname={location.pathname}
        sx={{
          '& + &': {
            mt: 3
          }
        }}
        {...section}
      />
    ));
  };

  const content = (
    <Scrollbar options={{ suppressScrollX: true }}>
      <Box sx={{ p: 2 }}>{renderNavSections()}</Box>
    </Scrollbar>
  );

  const sidebarLayout = (
    <>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.default',
            borderRadius: 1,
            display: 'flex',
            overflow: 'hidden',
            p: 2
          }}
        >
          <RouterLink to="/settings" style={{ textDecoration: 'none' }}>
            <UserAvatar
              user={user}
              style={{
                cursor: 'pointer',
                height: 48,
                width: 48
              }}
            />
          </RouterLink>
          <Box sx={{ ml: 2 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ textTransform: 'capitalize' }}
            >
              {user?.first_name} {user?.last_name}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {ROLES[user?.role]}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      {content}
      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography color="textPrimary" variant="subtitle2">
          © BVIRAL {new Date().getFullYear()}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          All Rights Reserved
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Build {version}
        </Typography>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {sidebarLayout}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
          height: 'calc(100% - 64px) !important',
          top: '64px !Important'
        }
      }}
      variant="temporary"
    >
      {sidebarLayout}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
