/* eslint-disable consistent-return */
import jwtDecode from 'jwt-decode';
import axios from 'axios';

class AuthApi {
  login({ email, password }) {
    return axios.post(`${process.env.REACT_APP_API_HOST}jwt/`, {
      email,
      password
    });
  }

  logout = () => {
    this.removeAccessTokenSession();
    this.removeRefreshTokenSession();
    this.removeRememberMe();
  };

  getRememberMe = () => {
    if (JSON.parse(window.localStorage.getItem('rememberMe')) === true) {
      return true;
    }
    return false;
  };

  removeRememberMe = () => {
    if (JSON.parse(window.localStorage.getItem('rememberMe')) === true) {
      window.localStorage.removeItem('rememberMe');
    } else {
      window.sessionStorage.removeItem('rememberMe');
    }
  };

  setRememberMe = (rememberMeValue) => {
    // always set remember me in localstorage
    window.localStorage.setItem('rememberMe', rememberMeValue);
  };

  setAccessRefreshTokens = (accessToken, refreshToken) => {
    const rememberMe = this.getRememberMe();
    if (rememberMe) {
      window.localStorage.setItem('accessToken', accessToken);
      window.localStorage.setItem('refreshToken', refreshToken);
    } else {
      window.sessionStorage.setItem('accessToken', accessToken);
      window.sessionStorage.setItem('refreshToken', refreshToken);
    }
  };

  removeAccessTokenSession = () => {
    window.localStorage.removeItem('accessToken');
    window.sessionStorage.removeItem('accessToken');
  };

  removeRefreshTokenSession = () => {
    window.localStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('refreshToken');
  };

  getAccessToken = () => {
    if (this.getRememberMe()) {
      return window.localStorage.getItem('accessToken');
    }
    return window.sessionStorage.getItem('accessToken');
  };

  getRefreshToken = () => {
    if (this.getRememberMe()) {
      return window.localStorage.getItem('refreshToken');
    }

    return window.sessionStorage.getItem('refreshToken');
  };

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Math.ceil(Date.now() / 1000);

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => {
    const accessToken = this.getRefreshToken();
    const refreshToken = this.getRefreshToken();
    if (accessToken && refreshToken && this.isValidToken(refreshToken)) {
      return true;
    }
    return false;
  };
}

export const authApi = new AuthApi();
