import axiosInstance from '../utils/axios';

class TeamsApi {
  getTeams(userId) {
    return axiosInstance.get(`user_teams/?is_on=true&user_id=${userId}`);
  }

  getAvailableTeams() {
    return axiosInstance.get('available_teams');
  }

  assignTeams(payload) {
    return axiosInstance.post('user_teams/assign_teams/', payload);
  }
}

export const teamsApi = new TeamsApi();
