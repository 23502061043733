import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import { Menu as MenuIcon, X as XIcon } from 'react-feather';
import AccountPopover from './AccountPopover';
import Logo from '../Logo';
import useAuth from 'src/hooks/useAuth';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.darkBg.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbarRouterLink = experimentalStyled(RouterLink)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, onMobileClose, isOpen, ...other } = props;
  const { user } = useAuth();
  const { isSourcer, isSourcerManager, isCreatorManager, isCreatorSourcer } =
    user;

  const crmRoleType = () => {
    if (isSourcer || isSourcerManager) {
      return 'Videos Sourcing';
    }
    if (isCreatorManager || isCreatorSourcer) {
      return 'Creators Sourcing';
    }
    return 'Review';
  };

  const handleSidebarOpen = () => {
    if (isOpen) {
      onMobileClose();
    } else {
      onSidebarMobileOpen();
    }
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={handleSidebarOpen}
          sx={{
            transition: 'all 0.1s linear',
            transform: `${isOpen ? 'rotate(90deg)' : 'rotate(0)'}`,
            display: {
              lg: 'none'
            }
          }}
        >
          {isOpen ? <XIcon fontSize="small" /> : <MenuIcon fontSize="small" />}
        </IconButton>
        <DashboardNavbarRouterLink
          to="/"
          sx={{
            display: {
              lg: 'flex',
              xs: 'none',
              md: 'none'
            }
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Logo color="white" height={40} />
            <Typography color="white" variant="h5">
              {crmRoleType()}
            </Typography>
          </Stack>
        </DashboardNavbarRouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  onMobileClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default DashboardNavbar;
